$containerPadding: 64px;

.intro {
  min-height: 100vh;
  background-image: linear-gradient(160deg, #ef00d7 0, rgba(239, 0, 215, .9) 5%, rgba(72, 0, 236, .5) 80%, rgba(60, 0, 146, .5) 100%);
  background-size: cover;
  background-attachment: fixed;

  .container {
    padding: $containerPadding;
    min-height: calc(100vh - #{$containerPadding} * 2);
    max-width: calc(100vw -  #{$containerPadding} * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;

    div {
      max-width: 100%; }

    h1 {
      animation: fadein 1s; }

    p {
      animation: fadein 2s;
      max-width: 100%;
      @media (min-width: 1000px) {
        width: 55%; } } }


  .person {
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed; }

  .scroll {
    display: flex;
    animation: fadein 4s;
    flex-wrap: wrap;
    justify-content: flex-start;
    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center; } }

  .scroll > div {
    padding: 16px;
    margin: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    transition-duration: 1s;
    width: fit-content; }

  .scroll > div:hover {
    background-color: rgba(255, 255, 255, 0.1); }

  @keyframes fadein {
    from {
      opacity: 0; }
    to {
      opacity: 1; } } }


