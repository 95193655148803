body {
  margin: 0;
  background-color: #050505;
  color: rgba(255,255,255,.9);
  font-family: 'Roboto Mono', 'Oxygen', 'Ubuntu', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 4rem;
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0; }
h1.underline {
  border-bottom: 8px solid white; }

h2 {
  font-size: 3rem;
  font-weight: 300;
  margin-block-start: 0;
  margin-block-end: 0; }
h2.underline {
  border-bottom: 4px solid white; }

h3 {
  font-size: 2rem;
  font-weight: 300;
  margin-block-start: 0;
  margin-block-end: 0; }
h3.underline {
  border-bottom: 2px solid white; }

p {
  font-size: 2rem;
  font-weight: 200;
  margin-block-start: 0;
  margin-block-end: 0; }

.bold {
  font-weight: 400; }

.underline {
  width: fit-content; }

#root {
  overflow: hidden; }

