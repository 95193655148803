$containerPadding: 64px;
.contact {

  .container {
    padding: $containerPadding;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch; }

  .icons {
    margin: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap; }

  .icons > div {
    margin: 32px; } }
