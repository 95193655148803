$containerPadding: 64px;
.skills {
  min-height: 100vh;

  .container {
    padding: $containerPadding;
    min-height: calc(100vh - #{$containerPadding} * 2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch; }

  .person {
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed; }

  .gradientBox {
    margin: 32px 0 32px 0;

    .gradient {
      height: 4px; }

    .content {
      background: rgba(0,0,0, 0.2);
      padding: 16px;
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-around; }

    .content > div {
      width: 200px;
      text-align: center;
      margin: 8px;
      padding: 8px;
      border-radius: 4px;
      font-size: 1.5rem;
      font-weight: 200;
      box-shadow: 0px 0px 32px 0px rgba(255, 255, 255,0.05); } } }

