@font-face {
  font-family: "Roboto Mono";
  src: url('./resources/fonts/Roboto_Mono/RobotoMono-Regular.ttf');
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Roboto Mono";
  src: url('./resources/fonts/Roboto_Mono/RobotoMono-Light.ttf');
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto Mono";
  src: url('./resources/fonts/Roboto_Mono/RobotoMono-Thin.ttf');
  font-weight: 100;
  font-style: normal; }
