$containerPadding: 64px;
.projects {
  min-height: 100vh;

  .content {
    padding: $containerPadding; }

  .gradient {
    background-size: cover;
    background-attachment: fixed; }

  .gradient.purple {
    background-image: linear-gradient(160deg,#ef00d7 0,rgba(239,0,215,.9) 5%,rgba(72,0,236,.5) 80%,rgba(60,0,146,.5) 100%); }

  .project.reversed {
    flex-direction: row-reverse; }

  .project {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: stretch;
    padding: 32px 0px 32px 0px;
    max-width: 100vw;
    overflow: hidden;

    .desc {
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 80vw;

      p {
        flex-grow: 2;
        min-height: 100px;
        width: fit-content;
        max-width: 100%;
        @media (min-width: 800px) {
          width: fit-content;
          max-width: 50vw; } }

      h2 {
        width: fit-content;
        display: flex;
        align-items: flex-end;

        svg {
          margin: 4px;
          font-size: 3rem;
          cursor: pointer;
          color: rgba(255, 255, 255, 0.5);
          transition: 0.5s; }

        svg:hover {
          color: white; } } }

    .img {
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 600px;
      max-height: 350px;

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%; } }

    img {
      box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.75);
      border-radius: 8px;
      height: 336px;
      width: auto; }

    .tags {
      display: flex;
      flex-wrap: wrap; }

    .tags > div {
      padding: 8px;
      margin: 8px 16px 8px 0px;
      background-color: rgba(0,0,0,0.5);
      width: min-content;
      border-radius: 8px; } }

  .iContainer {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 300px;

    div {
      transform: scale(0.2, 0.2);
      @media (min-width: 620px) {
        transform: scale(0.3, 0.3); } }

    iframe {
      border-radius: 26px;
      background: linear-gradient(to right, #642B73, #C6426E);
      box-shadow: 0px 0px 128px 0px rgba(0,0,0,0.75);
      width: 1920px;
      height: 1080px; } } }
